import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'smiles_2_outline_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="smiles_2_outline_28"><path fill-rule="evenodd" clip-rule="evenodd" d="M10 7a7 7 0 100 14 7 7 0 000-14zm7.5 11.975a4.99 4.99 0 004.249-1.668 1 1 0 10-1.499-1.323 2.993 2.993 0 01-1.747.974A8.987 8.987 0 0019 14a8.978 8.978 0 00-3.002-6.71 7 7 0 110 13.42 9.05 9.05 0 001.503-1.735zm-3.5 3.09A9 9 0 1110 5a8.946 8.946 0 014 .935 9 9 0 110 16.129zm-7.662-6.169a1 1 0 011.412.088A2.99 2.99 0 0010 17c.896 0 1.7-.391 2.25-1.016a1 1 0 111.5 1.324A4.99 4.99 0 0110 19a4.99 4.99 0 01-3.75-1.692 1 1 0 01.088-1.412zM6.75 11a1.25 1.25 0 100 2.5 1.25 1.25 0 000-2.5zM12 12.25a1.25 1.25 0 112.5 0 1.25 1.25 0 01-2.5 0zm8 0a1.25 1.25 0 112.5 0 1.25 1.25 0 01-2.5 0z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28Smiles2OutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28Smiles2Outline: FC<Icon28Smiles2OutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28Smiles2Outline as any).mountIcon = mountIcon;

export default Icon28Smiles2Outline;
