import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 56 56';
const id = 'payment_card_outline_56';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56 56" id="payment_card_outline_56"><path clip-rule="evenodd" d="M39.2 11H16.8c-2.29 0-3.827.002-5.01.099-1.149.094-1.698.261-2.06.446a5 5 0 00-2.185 2.185c-.185.362-.352.911-.446 2.06C7.002 16.973 7 18.51 7 20.8v.2h42v-.2c0-2.29-.002-3.827-.099-5.01-.094-1.149-.261-1.698-.446-2.06a5 5 0 00-2.185-2.185c-.362-.185-.911-.352-2.06-.446-1.183-.097-2.72-.099-5.01-.099zM7 35.2V24h42v11.2c0 2.29-.002 3.827-.099 5.01-.094 1.149-.261 1.698-.446 2.06a5 5 0 01-2.185 2.185c-.362.185-.911.352-2.06.446-1.183.097-2.72.099-5.01.099H16.8c-2.29 0-3.827-.002-5.01-.099-1.149-.094-1.698-.261-2.06-.446a5 5 0 01-2.185-2.185c-.185-.362-.352-.911-.446-2.06C7.002 39.027 7 37.49 7 35.2zM4.872 12.368C4 14.08 4 16.32 4 20.8v14.4c0 4.48 0 6.72.872 8.432a8 8 0 003.496 3.496C10.08 48 12.32 48 16.8 48h22.4c4.48 0 6.72 0 8.432-.872a8 8 0 003.496-3.496C52 41.92 52 39.68 52 35.2V20.8c0-4.48 0-6.72-.872-8.432a8 8 0 00-3.496-3.496C45.92 8 43.68 8 39.2 8H16.8c-4.48 0-6.72 0-8.432.872a8 8 0 00-3.496 3.496zm7.292 23.451c-.164.32-.164.74-.164 1.581v.2c0 .84 0 1.26.163 1.581a1.5 1.5 0 00.656.656c.32.163.74.163 1.581.163h.2c.84 0 1.26 0 1.581-.163a1.5 1.5 0 00.655-.656c.164-.32.164-.74.164-1.581v-.2c0-.84 0-1.26-.164-1.581a1.5 1.5 0 00-.655-.655C15.861 35 15.441 35 14.6 35h-.2c-.84 0-1.26 0-1.581.164a1.5 1.5 0 00-.656.655z" fill="currentColor" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon56PaymentCardOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon56PaymentCardOutline: FC<Icon56PaymentCardOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 56,
    height: !isNaN(props.height) ? +props.height : 56,
  }));
};

(Icon56PaymentCardOutline as any).mountIcon = mountIcon;

export default Icon56PaymentCardOutline;
