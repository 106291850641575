import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'add_square_outline_24';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="add_square_outline_24"><path d="M14.59 2.1c2.168 0 3.202.2 4.277.774a5.443 5.443 0 012.259 2.26c.574 1.074.774 2.108.774 4.276v5.18c0 2.168-.2 3.202-.774 4.277a5.443 5.443 0 01-2.26 2.259c-1.074.574-2.108.774-4.276.774H9.41c-2.168 0-3.202-.2-4.277-.774a5.443 5.443 0 01-2.259-2.26C2.3 17.793 2.1 16.759 2.1 14.59V9.41c0-2.168.2-3.202.774-4.277a5.443 5.443 0 012.26-2.259C6.207 2.3 7.241 2.1 9.41 2.1zm0 1.8H9.41c-1.896 0-2.65.146-3.428.561a3.644 3.644 0 00-1.52 1.521C4.045 6.76 3.9 7.514 3.9 9.41v5.18c0 1.896.146 2.65.561 3.428a3.643 3.643 0 001.521 1.52c.778.416 1.532.562 3.428.562h5.18c1.896 0 2.65-.146 3.428-.562a3.642 3.642 0 001.52-1.52c.416-.778.562-1.532.562-3.428V9.41c0-1.896-.146-2.65-.562-3.428a3.643 3.643 0 00-1.52-1.52c-.778-.417-1.532-.562-3.428-.562zM12 7.5a.9.9 0 01.893.787l.007.113-.001 2.7H15.6a.9.9 0 01.893.787L16.5 12a.9.9 0 01-.787.893l-.113.007h-2.701l.001 2.7a.9.9 0 01-.787.893L12 16.5a.9.9 0 01-.893-.787L11.1 15.6l-.001-2.7H8.4a.9.9 0 01-.893-.787L7.5 12a.9.9 0 01.787-.893L8.4 11.1h2.699l.001-2.7a.9.9 0 01.787-.893z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24AddSquareOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24AddSquareOutline: FC<Icon24AddSquareOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24AddSquareOutline as any).mountIcon = mountIcon;

export default Icon24AddSquareOutline;
