import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 56 56';
const id = 'users_3_outline_56';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56 56" id="users_3_outline_56"><g fill="none" fill-rule="evenodd"><path d="M0 0h56v56H0z" /><path d="M28 29c7.075 0 13 2.854 13 9 0 3.064-1.855 5-4.375 5h-17.25C16.759 43 15 41.572 15 38.5c0-6.527 5.848-9.5 13-9.5zm-14.443.001a1.5 1.5 0 01.03 2.996C9.706 32.225 7 33.984 7 37c0 .75.333 1 1 1h2.5l.144.007A1.5 1.5 0 0110.5 41H8l-.196-.004C5.724 40.916 4 39.546 4 37c0-4.992 4.145-7.69 9.412-7.997zm28.886 0l.145.002C47.855 29.31 52 32.008 52 37c0 2.545-1.724 3.916-3.804 3.996L48 41h-2.5a1.5 1.5 0 01-.144-2.993L45.5 38H48c.667 0 1-.25 1-1 0-3.016-2.705-4.775-6.588-5.003a1.5 1.5 0 01.031-2.996zM28 32c-5.71 0-10 2.18-10 6.5 0 1.151.245 1.45 1.11 1.494l.265.006h17.25c.834 0 1.375-.564 1.375-2 0-3.892-4.377-6-10-6zM16 12c.212 0 .423.01.632.028a1.5 1.5 0 01-.268 2.988 4 4 0 10.713 7.838l.158-.038a1.5 1.5 0 01.678 2.919l-.352.09-.325.066A7 7 0 1116 12zm24.004 0a7 7 0 11-1.236 13.89l-.325-.065-.352-.09a1.5 1.5 0 01.677-2.919l.159.038a3.965 3.965 0 001.077.146 4 4 0 10-.364-7.984 1.5 1.5 0 11-.269-2.988c.21-.019.42-.028.633-.028zM28 10a8 8 0 110 16 8 8 0 010-16zm0 3a5 5 0 100 10 5 5 0 000-10z" fill="currentColor" fill-rule="nonzero" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon56Users3OutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon56Users3Outline: FC<Icon56Users3OutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 56,
    height: !isNaN(props.height) ? +props.height : 56,
  }));
};

(Icon56Users3Outline as any).mountIcon = mountIcon;

export default Icon56Users3Outline;
