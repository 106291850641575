import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 56 56';
const id = 'article_outline_56';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56 56" id="article_outline_56"><path clip-rule="evenodd" d="M36.102 6.001L20.32 6c-2.887.018-5.808.121-8.41 1.513-2.038 1.09-3.566 2.774-4.61 4.815-.925 1.89-1.274 3.842-1.298 7.57L6 35.68c.018 2.887.121 5.809 1.513 8.41a10.586 10.586 0 004.395 4.396c2.603 1.392 5.524 1.494 8.411 1.513h15.362c2.887-.018 5.809-.121 8.41-1.513a10.586 10.586 0 004.396-4.395C49.88 41.49 49.981 38.568 50 35.68V20.319c-.018-2.887-.121-5.809-1.513-8.41a10.586 10.586 0 00-4.395-4.396C41.589 6.23 38.88 6.019 36.102 6zM9 35.681V21c.005-.312.008-.625.01-.94.019-2.304.038-4.66 1.148-6.737a7.586 7.586 0 013.165-3.165c2.117-1.132 4.545-1.145 6.89-1.157L20.319 9h15.362c3.837 0 5.39.3 6.996 1.158a7.586 7.586 0 013.165 3.165c1.11 2.076 1.129 4.433 1.148 6.737.002.315.005.628.01.94v14.763c-.013 2.34-.026 4.797-1.158 6.914a7.586 7.586 0 01-3.165 3.165c-2.117 1.132-4.545 1.145-6.89 1.157H20.213c-2.345-.012-4.773-.025-6.89-1.157a7.586 7.586 0 01-3.165-3.165C9.026 40.56 9.013 38.104 9 35.765zM17.5 17a1.5 1.5 0 000 3h21a1.5 1.5 0 000-3zM16 28a1.5 1.5 0 011.5-1.5h21a1.5 1.5 0 010 3h-21A1.5 1.5 0 0116 28zm1.5 8a1.5 1.5 0 000 3h9a1.5 1.5 0 000-3z" fill="currentColor" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon56ArticleOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon56ArticleOutline: FC<Icon56ArticleOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 56,
    height: !isNaN(props.height) ? +props.height : 56,
  }));
};

(Icon56ArticleOutline as any).mountIcon = mountIcon;

export default Icon56ArticleOutline;
