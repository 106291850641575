import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'inbox_outline_28';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="inbox_outline_28"><g fill="none" fill-rule="evenodd"><path d="M0 0h28v28H0z" /><path d="M19.815 4c1.204 0 1.803.096 2.448.38.604.268 1.11.673 1.501 1.205.419.567.642 1.132.903 2.308l1.31 5.89L26 14v3.872c0 1.777-.168 2.648-.652 3.553a4.634 4.634 0 01-1.923 1.923c-.905.484-1.776.652-3.553.652H8.128c-1.777 0-2.648-.168-3.553-.652a4.634 4.634 0 01-1.923-1.923C2.168 20.52 2 19.65 2 17.872V14l.024-.217 1.309-5.89c.261-1.176.484-1.74.903-2.308a3.701 3.701 0 011.5-1.204C6.383 4.096 6.982 4 8.187 4h11.629zM4 17.872c0 1.475.108 2.034.416 2.61.255.478.624.847 1.102 1.102.576.308 1.135.416 2.61.416h11.744c1.475 0 2.034-.108 2.61-.416a2.635 2.635 0 001.102-1.102c.308-.576.416-1.135.416-2.61V15h-5.1a5.002 5.002 0 01-9.8 0H4v2.872zM20.023 6.001L8.185 6c-.95 0-1.287.054-1.64.21-.285.126-.514.31-.7.561-.228.31-.354.629-.56 1.556L4.246 13H10a1 1 0 01.993.883L11 14a3 3 0 006 0 1 1 0 011-1h5.753l-1.038-4.673c-.206-.927-.332-1.245-.56-1.556a1.702 1.702 0 00-.7-.561c-.326-.144-.639-.201-1.432-.209z" fill="currentColor" fill-rule="nonzero" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28InboxOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28InboxOutline: FC<Icon28InboxOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28InboxOutline as any).mountIcon = mountIcon;

export default Icon28InboxOutline;
