import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'users_outline_24';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="users_outline_24"><path d="M17 13.5c3.689 0 6.9 1.31 6.9 4.257 0 1.554-.644 2.243-1.959 2.243h-5.596c-.543 0-.927-.551-.82-1.084.076-.373.39-.716.77-.716l5.783.002s.022-.297.022-.445c0-1.512-2.318-2.457-5.1-2.457-.472 0-.93.027-1.367.08a.902.902 0 01-.85-.398c-.35-.516-.166-1.296.453-1.375A14.063 14.063 0 0117 13.5zm-10 0c3.688 0 6.9 1.31 6.9 4.257 0 1.554-.644 2.243-1.959 2.243H2.06C.744 20 .1 19.311.1 17.757.1 14.81 3.312 13.5 7 13.5zm0 1.8c-2.782 0-5.1.945-5.1 2.457 0 .149.02.445.02.445h10.158s.022-.297.022-.445c0-1.512-2.318-2.457-5.1-2.457zM17 4.2a4.15 4.15 0 014.15 4.15A4.15 4.15 0 0117 12.5a4.15 4.15 0 01-4.15-4.15A4.15 4.15 0 0117 4.2zm-10 0a4.15 4.15 0 014.15 4.15A4.15 4.15 0 017 12.5a4.15 4.15 0 01-4.15-4.15A4.15 4.15 0 017 4.2zM17 6a2.35 2.35 0 100 4.7A2.35 2.35 0 1017 6zM7 6a2.35 2.35 0 100 4.7A2.35 2.35 0 107 6z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24UsersOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24UsersOutline: FC<Icon24UsersOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24UsersOutline as any).mountIcon = mountIcon;

export default Icon24UsersOutline;
