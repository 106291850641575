import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 56 56';
const id = 'camera_off_outline_56';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56 56" id="camera_off_outline_56"><g fill="currentColor"><path clip-rule="evenodd" d="M26.5 19.5A1.5 1.5 0 0128 18c5.523 0 10 4.477 10 10a1.5 1.5 0 01-3 0 7 7 0 00-7-7 1.5 1.5 0 01-1.5-1.5zM6.128 14.424C4.758 16.284 4 18.774 4 21.437V34c0 .582.08 1.804.228 3.038.143 1.185.374 2.632.747 3.535.53 1.282 1.294 2.578 2.307 3.629 1.134 1.359 2.79 2.292 4.466 2.887 1.714.608 3.584.911 5.252.911h21c2.788 0 4.984-.348 6.798-1.08l3.641 3.64a1.5 1.5 0 002.122-2.12l-42-42a1.5 1.5 0 10-2.122 2.12l3.155 3.156c-1.389.524-2.568 1.488-3.466 2.708zM12 14.28c-1.323 0-2.53.667-3.457 1.924C7.607 17.475 7 19.315 7 21.437V34c0 .418.066 1.51.207 2.68.147 1.222.346 2.276.54 2.747.422 1.02.998 1.98 1.772 2.772.823 1.006 2.026 1.634 3.233 2.062 1.386.492 2.916.739 4.248.739h21c1.83 0 3.263-.165 4.421-.457l-8.49-8.49A9.962 9.962 0 0128 38c-5.523 0-10-4.477-10-10 0-2.22.724-4.272 1.948-5.931l-7.79-7.79zM21 28a7 7 0 007 7 6.963 6.963 0 003.775-1.104l-9.671-9.671A6.963 6.963 0 0021 28z" fill-rule="evenodd" /><path d="M22 5.5c-1.21 0-2.21.592-2.92 1.16-.724.578-1.326 1.28-1.74 1.773-.102.121-.199.248-.297.372a1.5 1.5 0 102.348 1.868l.254-.32c.407-.485.842-.978 1.308-1.35.482-.386.826-.503 1.047-.503h12c.221 0 .565.117 1.047.502.498.399.94.89 1.336 1.386l.43.542 1.015 1.273c.625.784 1.571 1.284 2.422 1.588a8.434 8.434 0 002.75.489h1c1.323 0 2.53.666 3.457 1.923.936 1.272 1.543 3.112 1.543 5.234V34c0 .495 0 1.43-.105 2.476-.107 1.069-.312 2.129-.663 2.909-.106.235-.217.468-.333.697a1.5 1.5 0 002.675 1.358c.14-.275.27-.55.394-.824.549-1.22.794-2.66.912-3.842.12-1.2.12-2.26.12-2.767v-12.57c0-2.664-.757-5.152-2.128-7.013C48.492 12.55 46.45 11.28 44 11.28h-1a5.443 5.443 0 01-1.742-.315c-.608-.217-.957-.472-1.086-.634l-.986-1.238-.526-.66c-.414-.493-1.016-1.195-1.74-1.774C36.21 6.092 35.21 5.5 34 5.5z" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon56CameraOffOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon56CameraOffOutline: FC<Icon56CameraOffOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 56,
    height: !isNaN(props.height) ? +props.height : 56,
  }));
};

(Icon56CameraOffOutline as any).mountIcon = mountIcon;

export default Icon56CameraOffOutline;
