import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 36 36';
const id = 'money_circle_outline_36';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" id="money_circle_outline_36"><g clip-rule="evenodd" fill="currentColor" fill-rule="evenodd"><path d="M14.31 11a.101.101 0 00-.102.101v6.912h-1.006c-.093 0-.14 0-.179.003-.546.039-.981.474-1.02 1.02-.003.04-.003.086-.003.18s0 .139.003.178c.039.547.474.982 1.02 1.02.04.003.086.003.18.003h1.007v1.804h-1.158a1.052 1.052 0 000 2.104h1.158v.911c0 .084 0 .126.002.161.036.553.476.994 1.03 1.03.035.002.076.002.16.002s.126 0 .161-.003a1.102 1.102 0 001.03-1.029l.001-.16v-.912h2.068a1.052 1.052 0 000-2.104h-2.068v-1.804h3.124a4.709 4.709 0 100-9.417zm5.454 7.013h-3.17v-4.809h3.17a2.404 2.404 0 010 4.809z" /><path d="M18 3c8.284 0 15 6.716 15 15 0 8.284-6.716 15-15 15-8.284 0-15-6.716-15-15C3 9.716 9.716 3 18 3zm0 2.5C11.096 5.5 5.5 11.096 5.5 18S11.096 30.5 18 30.5 30.5 24.904 30.5 18 24.904 5.5 18 5.5z" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon36MoneyCircleOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon36MoneyCircleOutline: FC<Icon36MoneyCircleOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 36,
    height: !isNaN(props.height) ? +props.height : 36,
  }));
};

(Icon36MoneyCircleOutline as any).mountIcon = mountIcon;

export default Icon36MoneyCircleOutline;
