import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 20 20';
const id = 'home_outline_20';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" id="home_outline_20"><g fill="none" fill-rule="evenodd"><path opacity=".4" d="M0 0h20v20H0z" /><path d="M3 10.999h-.04l-.182-.011a2.045 2.045 0 01-1.109-3.585L8.21 1.796a2.75 2.75 0 013.58 0l6.54 5.607A2.045 2.045 0 0117 11v4.25a2.25 2.25 0 01-2.25 2.25h-2.8a.95.95 0 01-.95-.95V13.5a1 1 0 00-2 0v3.05a.95.95 0 01-.95.95h-2.8A2.25 2.25 0 013 15.25v-4.251zM14.75 16a.75.75 0 00.75-.75v-4.9c0-.47.38-.85.85-.85H17a.545.545 0 00.355-.958l-6.542-5.607a1.25 1.25 0 00-1.626 0L2.645 8.542a.545.545 0 00-.059.768c.09.105.217.172.314.183L3 9.5h.65c.47 0 .85.38.85.85v4.9c0 .414.336.75.75.75H7.5v-2.5a2.5 2.5 0 115 0V16h2.25z" fill="currentColor" fill-rule="nonzero" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon20HomeOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon20HomeOutline: FC<Icon20HomeOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 20,
    height: !isNaN(props.height) ? +props.height : 20,
  }));
};

(Icon20HomeOutline as any).mountIcon = mountIcon;

export default Icon20HomeOutline;
