import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 36 36';
const id = 'like_36';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" id="like_36"><g fill="none" fill-rule="evenodd"><path d="M0 0h36v36H0z" /><path d="M18.952 8.45c3.4-2.525 8.147-2.68 11.104.006.117.107.232.216.343.33 3.468 3.504 3.468 9.18-.054 12.737l-10.832 9.894a2.25 2.25 0 01-3.035-.001L5.6 21.47c-3.468-3.505-3.468-9.18 0-12.685.111-.113.226-.222.343-.329 2.957-2.686 7.705-2.531 11.177.052.3.253.592.524.878.812.301-.302.617-.59.953-.87z" fill="currentColor" fill-rule="nonzero" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon36LikeProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon36Like: FC<Icon36LikeProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 36,
    height: !isNaN(props.height) ? +props.height : 36,
  }));
};

(Icon36Like as any).mountIcon = mountIcon;

export default Icon36Like;
