import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'user_add_badge_outline_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="user_add_badge_outline_28"><path fill-rule="evenodd" clip-rule="evenodd" d="M14 13.5a5.5 5.5 0 100-11 5.5 5.5 0 000 11zm0-2a3.5 3.5 0 100-7 3.5 3.5 0 000 7zM19 26a6 6 0 100-12 6 6 0 000 12zm1-9a1 1 0 10-2 0v2h-2a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2v-2z" fill="currentColor" /><path d="M11.187 17.739a1 1 0 00-.374-1.965c-2.728.52-4.228 1.714-5.021 2.909a4.9 4.9 0 00-.686 1.553 4.012 4.012 0 00-.104.677 2.115 2.115 0 00-.002.053v.032S5 21 6 21H5v.5A2.5 2.5 0 007.5 24h3a1 1 0 100-2h-3a.5.5 0 01-.5-.5v-.488l.004-.05a2.07 2.07 0 01.046-.256c.056-.23.17-.559.408-.917.457-.689 1.457-1.617 3.73-2.05z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28UserAddBadgeOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28UserAddBadgeOutline: FC<Icon28UserAddBadgeOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28UserAddBadgeOutline as any).mountIcon = mountIcon;

export default Icon28UserAddBadgeOutline;
