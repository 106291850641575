import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'question_outline_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="question_outline_28"><path clip-rule="evenodd" d="M14.018 5.5c-5.385 0-9.481 3.719-9.481 7.999 0 1.537.515 2.979 1.422 4.2a1 1 0 01.197.597c0 1.042-.274 2.052-.61 2.963a25.566 25.566 0 01-.705 1.675c2.057-.218 3.405-.877 4.219-1.834a1 1 0 011.113-.289c1.172.44 2.473.687 3.845.687 5.385 0 9.482-3.719 9.482-8 0-4.28-4.096-7.998-9.482-7.998zM2.537 13.499c0-5.66 5.289-9.999 11.482-9.999C20.21 3.5 25.5 7.839 25.5 13.499s-5.289 9.999-11.482 9.999c-1.373 0-2.693-.21-3.918-.597C8.657 24.25 6.556 24.898 3.989 25c-1.046.041-1.8-1.047-1.363-2.021l.27-.597c.276-.605.547-1.199.775-1.815.256-.694.43-1.349.475-1.966-1.017-1.48-1.608-3.228-1.608-5.101zm11.45-3.497a.924.924 0 00-.894.692 1 1 0 01-1.936-.499 2.924 2.924 0 015.754.73c0 1.29-.71 1.966-1.189 2.414-.47.438-.68.642-.744 1.094a1 1 0 11-1.98-.283c.167-1.168.852-1.803 1.29-2.209l.07-.064c.421-.393.553-.559.553-.952a.923.923 0 00-.923-.923zM14 19a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5z" fill="currentColor" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28QuestionOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28QuestionOutline: FC<Icon28QuestionOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28QuestionOutline as any).mountIcon = mountIcon;

export default Icon28QuestionOutline;
