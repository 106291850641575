import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'money_circle_24';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="money_circle_24"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z" /><path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm1.77-6.004a.4.4 0 00.4-.4v-.45a.4.4 0 00-.4-.4h-2.772v-1.249h2.078c2.187 0 3.633-1.258 3.633-3.29 0-2.039-1.414-3.757-3.578-3.757H9.648a.4.4 0 00-.4.4v5.163H8.14a.4.4 0 00-.4.4v.668c0 .221.18.4.4.4h1.108v1.265H8.14a.4.4 0 00-.4.4v.45c0 .22.18.4.4.4h1.108v1.354c0 .22.179.4.4.4h.95a.4.4 0 00.4-.4v-1.354h2.772zm-2.772-7.999h1.64c1.524 0 2.282.773 2.282 2 0 1.227-.758 2-2.282 2h-1.64v-4z" fill="currentColor" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24MoneyCircleProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24MoneyCircle: FC<Icon24MoneyCircleProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24MoneyCircle as any).mountIcon = mountIcon;

export default Icon24MoneyCircle;
