import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'horse_toy_outline_24';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="horse_toy_outline_24"><path clip-rule="evenodd" d="M10.707 8.2H9c-2.631 0-4.08 2.106-4.415 3.783l-.704 3.52a2.609 2.609 0 00-.522 5.006c2.423.95 5.184 1.395 8.243 1.395s5.82-.446 8.244-1.395a2.61 2.61 0 00-.478-4.997l-.603-3.333c.086.004.154.003.198.003h.134a2.408 2.408 0 002.408-2.408V7.568c.317-.244.61-.608.739-1.15a2.162 2.162 0 00-.128-1.357 2.976 2.976 0 00-.472-.744c-.51-.602-1.133-1.179-1.951-1.594-.827-.42-1.751-.623-2.793-.623-3.496 0-6.045 2.837-6.193 6.1zm5.772 1.455l-.015-.083a.457.457 0 01-.005-.067c0-.182.064-.569.167-.61.1-.04.138-.01.246.074l.054.04 1.158.968c.129.128.236.216.332.276.208.129.364.129.574.129h.107a.608.608 0 00.608-.608V6.707c0-.128 0-.192.02-.24a.225.225 0 01.083-.104c.042-.03.118-.047.27-.08.49-.11.553-.377.192-.803a5.837 5.837 0 00-.484-.51 4.368 4.368 0 00-.439-.359c-.635-.45-1.403-.711-2.447-.711-2.504 0-4.4 2.1-4.4 4.6v.86c0 .224 0 .336-.044.422a.4.4 0 01-.174.174c-.086.044-.198.044-.422.044H9c-1.5 0-2.422 1.2-2.65 2.336l-.906 4.53c-.049.244-.073.366-.144.447a.413.413 0 01-.231.13l-.009.001c-.106.016-.217-.027-.44-.114l-.014-.006a.81.81 0 00-.591 1.51c.006.001.011.004.017.006a15.774 15.774 0 001.157.4 17.96 17.96 0 001.566.4c.342.071.69.134 1.045.19 1.19.183 2.457.274 3.802.274 1.346 0 2.613-.091 3.803-.275a20.82 20.82 0 001.045-.189l.032-.007a17.905 17.905 0 002.16-.597c.185-.065.367-.132.547-.203a.81.81 0 00-.59-1.509c-.22.086-.329.129-.433.114a.416.416 0 01-.252-.14c-.067-.081-.088-.202-.132-.444zm-.859 8.313c.033-.077.033-.15.033-.294a4.051 4.051 0 10-8.102 0c0 .144 0 .217.034.294l.002.005a.443.443 0 00.146.17c.072.047.153.06.314.085a23.4 23.4 0 003.555.256c1.266 0 2.45-.086 3.556-.256.161-.025.242-.038.314-.085a.442.442 0 00.146-.17zm-2.037-1.365a2.25 2.25 0 00-3.961 0c.629.054 1.289.081 1.98.081.692 0 1.352-.027 1.98-.08z" fill="currentColor" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24HorseToyOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24HorseToyOutline: FC<Icon24HorseToyOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24HorseToyOutline as any).mountIcon = mountIcon;

export default Icon24HorseToyOutline;
