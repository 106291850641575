import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'narrative_outline_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="narrative_outline_28"><g clip-rule="evenodd" fill="currentColor" fill-rule="evenodd"><path d="M24.113 19.089c.641-.88.88-1.855.887-2.931A4.158 4.158 0 0020.842 12c-.97.025-1.892.277-2.705.824L18 12.92l-.137-.096A4.764 4.764 0 0015.158 12 4.158 4.158 0 0011 16.158c-.007 1.071.25 2.058.886 2.93.667.916 1.779 1.899 3.556 3.299l1.647 1.297a1.471 1.471 0 001.822 0l1.646-1.297c1.778-1.4 2.89-2.383 3.556-3.298zm-10.61-1.178c.483.663 1.381 1.49 3.177 2.905l1.32 1.04 1.32-1.04c1.795-1.414 2.694-2.242 3.176-2.905.39-.535.5-1.1.504-1.753A2.158 2.158 0 0020.842 14c-.803 0-1.498.3-2.135.937l-.353.353a.5.5 0 01-.708 0l-.353-.353c-.637-.636-1.332-.937-2.135-.937A2.158 2.158 0 0013 16.158c-.004.65.116 1.222.503 1.753z" /><path d="M7.647 3.474l3.364-.901c.778-.209 1.42-.38 1.954-.478.555-.101 1.08-.14 1.612-.026a4 4 0 012.14 1.236c.365.404.594.878.784 1.409.183.511.355 1.153.563 1.93l.68 2.54a1 1 0 01-1.931.517l-.67-2.499c-.222-.827-.373-1.39-.525-1.815-.148-.414-.269-.614-.386-.744a2 2 0 00-1.07-.618c-.173-.037-.406-.041-.839.037-.444.081-1.007.231-1.834.453l-3.284.88c-.828.222-1.39.373-1.815.525-.414.148-.614.27-.744.387a2 2 0 00-.619 1.07c-.036.172-.04.405.038.838.081.444.231 1.007.453 1.834L8.21 20.095c.221.827.373 1.39.525 1.815.148.414.269.614.386.744a2 2 0 001.07.618c.337.072.786.01 2.092-.335a1 1 0 01.51 1.933c-1.183.313-2.125.548-3.017.358a4 4 0 01-2.141-1.236c-.365-.405-.594-.878-.784-1.41-.182-.51-.354-1.152-.563-1.93L3.575 10.527c-.208-.778-.38-1.42-.477-1.954-.102-.554-.14-1.079-.027-1.612a4 4 0 011.236-2.14c.405-.365.879-.594 1.41-.784.511-.183 1.153-.355 1.93-.563z" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28NarrativeOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28NarrativeOutline: FC<Icon28NarrativeOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28NarrativeOutline as any).mountIcon = mountIcon;

export default Icon28NarrativeOutline;
