import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 56 56';
const id = 'lock_outline_56';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56 56" id="lock_outline_56"><g fill="currentColor"><path d="M28 31a3 3 0 00-1.5 5.599V39.5a1.5 1.5 0 003 0v-2.901A2.999 2.999 0 0028 31z" /><path clip-rule="evenodd" d="M28 4c-6.075 0-11 4.925-11 11v5.04c-.36.015-.7.034-1.02.06-1.26.103-2.344.32-3.339.826a8.5 8.5 0 00-3.715 3.715c-.507.996-.723 2.08-.826 3.34C8 29.208 8 30.73 8 32.634v6.73c0 1.905 0 3.426.1 4.655.103 1.26.32 2.344.826 3.339a8.5 8.5 0 003.715 3.715c.995.507 2.08.723 3.34.826 1.228.1 2.749.1 4.654.1h14.73c1.905 0 3.426 0 4.655-.1 1.26-.103 2.343-.32 3.339-.826a8.5 8.5 0 003.715-3.715c.507-.995.723-2.08.826-3.34.1-1.228.1-2.749.1-4.654v-6.73c0-1.905 0-3.426-.1-4.655-.103-1.26-.32-2.343-.826-3.339a8.5 8.5 0 00-3.715-3.715c-.996-.507-2.08-.723-3.34-.826-.319-.026-.658-.045-1.019-.06V15c0-6.075-4.925-11-11-11zm-8 11a8 8 0 1116 0v5H20zm-5.997 8.6c.502-.256 1.147-.422 2.222-.51 1.092-.089 2.49-.09 4.475-.09h14.6c1.985 0 3.383.001 4.476.09 1.074.088 1.719.254 2.221.51a5.5 5.5 0 012.404 2.403c.255.502.42 1.147.509 2.222.089 1.092.09 2.49.09 4.475v6.6c0 1.985-.001 3.383-.09 4.476-.088 1.074-.254 1.719-.51 2.221a5.5 5.5 0 01-2.403 2.404c-.502.255-1.147.42-2.222.509-1.092.089-2.49.09-4.475.09H20.7c-1.985 0-3.383-.001-4.475-.09-1.075-.088-1.72-.254-2.222-.51a5.5 5.5 0 01-2.404-2.403c-.255-.502-.42-1.147-.509-2.222-.089-1.092-.09-2.49-.09-4.475v-6.6c0-1.985.001-3.383.09-4.475.088-1.075.254-1.72.51-2.222a5.5 5.5 0 012.403-2.404z" fill-rule="evenodd" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon56LockOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon56LockOutline: FC<Icon56LockOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 56,
    height: !isNaN(props.height) ? +props.height : 56,
  }));
};

(Icon56LockOutline as any).mountIcon = mountIcon;

export default Icon56LockOutline;
