import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'notification_add_outline_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="notification_add_outline_28"><path d="M13.955 3.35c.78 0 1.542.116 2.27.341a.9.9 0 01-.533 1.72 5.85 5.85 0 00-1.737-.261c-3.348 0-5.988 2.76-6.101 6.433l-.004.246v1.828c0 .52-.148.995-.413 1.462a6.25 6.25 0 01-.61.869l-.233.277-.36.418-.251.304-.2.258c-.354.478-.433.739-.433 1.093l.004.202c.008.135.028.2.073.298.133.283.5.484 1.337.51l.186.002h14c.937 0 1.356-.21 1.512-.515.072-.142.088-.222.088-.497 0-.384-.093-.657-.527-1.216l-.226-.28-.28-.33a.9.9 0 111.362-1.177c1.13 1.308 1.471 1.98 1.471 3.003 0 .54-.072.895-.284 1.312-.466.918-1.437 1.44-2.86 1.495l-.256.005h-2.677l-.02.072c-.328 1.113-.864 1.97-1.587 2.55-.726.58-1.639.878-2.716.878s-1.99-.298-2.716-.879c-.663-.53-1.168-1.295-1.5-2.276l-.109-.345H6.95c-1.612 0-2.68-.544-3.152-1.546-.188-.4-.248-.73-.248-1.266 0-.966.303-1.619 1.288-2.79l.451-.528c.277-.326.463-.577.582-.787.097-.173.154-.325.172-.469l.007-.107V11.83c0-4.762 3.459-8.479 7.905-8.479zm2.42 17.802h-4.85c.216.547.5.946.833 1.214.396.316.91.484 1.592.484s1.196-.168 1.591-.484c.335-.268.618-.668.835-1.214zM19.95 4.75a1 1 0 01.993.883l.007.117v2.5h2.5a1 1 0 01.117 1.993l-.117.007h-2.5v2.5a1 1 0 01-1.993.117l-.007-.117v-2.5h-2.5a1 1 0 01-.117-1.993l.117-.007h2.5v-2.5a1 1 0 011-1z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28NotificationAddOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28NotificationAddOutline: FC<Icon28NotificationAddOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28NotificationAddOutline as any).mountIcon = mountIcon;

export default Icon28NotificationAddOutline;
