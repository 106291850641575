import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'user_star_badge_slash_outline_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="user_star_badge_slash_outline_28"><path fill-rule="evenodd" clip-rule="evenodd" d="M17.5 9a3.5 3.5 0 00-6.106-2.336l-.003-.002a1 1 0 11-1.419-1.407 5.5 5.5 0 117.773 7.773 1 1 0 11-1.408-1.417l-.003-.003A3.491 3.491 0 0017.5 9zM5.707 5.293a1 1 0 00-1.414 1.414L14.555 16.97a6 6 0 008.475 8.475l2.263 2.263a1 1 0 101.414-1.414l-21-21zM16.065 19.51l.94-.093 4.428 4.43c-.092.34-.466.48-.841.21l-1.591-1.15-1.591 1.15c-.495.356-.992-.003-.832-.599l.486-1.814-1.31-1.113c-.451-.384-.273-.964.311-1.021zm-5.54-1.624c-1.81.474-2.657 1.285-3.067 1.902a2.902 2.902 0 00-.408.917 2.017 2.017 0 00-.05.306v.488a.5.5 0 00.5.5h3a1 1 0 110 2h-3A2.5 2.5 0 015 21.5v-.534a2.115 2.115 0 01.015-.215c.014-.13.04-.306.091-.515.1-.417.299-.97.686-1.553.707-1.066 1.978-2.131 4.18-2.718a1 1 0 11.553 1.922zm13.926 3.321c.205.206.549.083.549-.208a6 6 0 00-6-6c-.29 0-.414.344-.208.55l5.659 5.658z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28UserStarBadgeSlashOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28UserStarBadgeSlashOutline: FC<Icon28UserStarBadgeSlashOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28UserStarBadgeSlashOutline as any).mountIcon = mountIcon;

export default Icon28UserStarBadgeSlashOutline;
