import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'crown_outline_24';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="crown_outline_24"><path fill-rule="evenodd" clip-rule="evenodd" d="M13.012 2.229a2.344 2.344 0 00-2.02 0c-.47.233-.763.667-.979 1.054-.222.399-.462.935-.748 1.574L8.107 7.452l-1.15-.687c-.679-.405-1.24-.74-1.69-.958-.427-.206-.958-.416-1.507-.316a2.2 2.2 0 00-1.603 1.243c-.234.507-.162 1.073-.068 1.538.099.491.284 1.117.507 1.875l1.24 4.204c.066.227.1.341.127.457.05.219.082.441.096.666.007.118.007.237.007.474 0 .53 0 .981.03 1.352.032.389.101.767.286 1.13a2.9 2.9 0 001.267 1.267c.362.184.74.254 1.13.286.37.03.821.017 1.351.017h7.742c.53 0 .981.013 1.352-.017.389-.032.767-.102 1.13-.286a2.9 2.9 0 001.267-1.268c.184-.362.254-.74.285-1.13.03-.37.03-.821.03-1.35 0-.235 0-.352.007-.468.014-.227.046-.452.098-.673.026-.114.059-.226.125-.45l1.241-4.211c.224-.758.408-1.384.508-1.875.094-.465.165-1.031-.069-1.538a2.2 2.2 0 00-1.602-1.243c-.55-.1-1.08.11-1.508.316-.45.219-1.01.553-1.689.958l-1.15.687-1.159-2.595c-.286-.64-.525-1.175-.748-1.574-.216-.387-.508-.82-.978-1.054zm5.125 13.683H5.866c0 .575 0 .953.024 1.241.023.278.062.393.096.46a1.1 1.1 0 00.48.48c.066.033.182.073.46.096.288.023.665.024 1.24.024h7.67c.575 0 .953-.001 1.241-.024.278-.023.393-.063.459-.096a1.1 1.1 0 00.48-.48c.034-.067.074-.182.096-.46.024-.288.025-.666.025-1.24zM11.602 4.13c.185-.314.64-.286.817.031.178.32.385.78.693 1.47l1.554 3.48a.9.9 0 001.284.406l1.982-1.184c.725-.433.953-.58 1.589-.906l.022-.01c.326-.166.68.14.607.499-.081.404-.243.954-.481 1.764l-1.307 4.432H5.642L4.334 9.68a42.01 42.01 0 01-.482-1.764c-.09-.362.294-.65.63-.489.37.18.863.473 1.589.906l1.982 1.184a.9.9 0 001.283-.406l1.554-3.48c.308-.69.515-1.15.693-1.47l.018-.03z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24CrownOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24CrownOutline: FC<Icon24CrownOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24CrownOutline as any).mountIcon = mountIcon;

export default Icon24CrownOutline;
