import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 44 44';
const id = 'smile_outline_44';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="smile_outline_44"><g fill="none" fill-rule="evenodd"><path d="M0 0h44v44H0z" /><path d="M22 40c-9.941 0-18-8.059-18-18S12.059 4 22 4s18 8.059 18 18-8.059 18-18 18zm0-3c8.284 0 15-6.716 15-15S30.284 7 22 7 7 13.716 7 22s6.716 15 15 15zm-6.582-8.863a1.5 1.5 0 012.194-2.046 6 6 0 008.775.002 1.5 1.5 0 112.191 2.049 9 9 0 01-13.16-.005zm11.645-7.262a2.437 2.437 0 110-4.875 2.437 2.437 0 010 4.875zm-10.125 0a2.437 2.437 0 110-4.875 2.437 2.437 0 010 4.875z" fill="currentColor" fill-rule="nonzero" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon44SmileOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon44SmileOutline: FC<Icon44SmileOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 44,
    height: !isNaN(props.height) ? +props.height : 44,
  }));
};

(Icon44SmileOutline as any).mountIcon = mountIcon;

export default Icon44SmileOutline;
