import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'payment_card_outline_24';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="payment_card_outline_24"><path fill-rule="evenodd" clip-rule="evenodd" d="M17.795 4.1c1.152 0 1.74.113 2.35.44a3.17 3.17 0 011.315 1.314c.302.565.421 1.108.438 2.095l.002.256v7.59l-.002.256c-.017.987-.136 1.53-.438 2.095a3.171 3.171 0 01-1.315 1.314l-.14.071c-.564.272-1.147.369-2.21.369H6.205c-1.063 0-1.646-.097-2.21-.369l-.14-.071a3.172 3.172 0 01-1.315-1.315c-.301-.564-.421-1.107-.438-2.094l-.002-.256v-7.59c0-1.152.113-1.74.44-2.35A3.171 3.171 0 013.855 4.54c.564-.301 1.107-.421 2.094-.438l.256-.002h11.59zM20.1 9.9l.001 5.895c0 .88-.06 1.187-.228 1.502-.134.25-.325.441-.575.575-.291.156-.575.218-1.309.227l-.193.001H6.205l-.193-.001c-.734-.009-1.018-.071-1.309-.227a1.372 1.372 0 01-.575-.575c-.169-.315-.228-.622-.228-1.502L3.899 9.9h16.2zm-13.894-4h11.59l.193.001c.735.009 1.018.071 1.309.227.25.134.441.325.575.575.156.29.218.574.227 1.309l-.001.088H3.9l.001-.088c.009-.735.071-1.018.227-1.309.134-.25.325-.441.575-.575.315-.169.622-.228 1.502-.228z" fill="currentColor" /><path d="M6 14.8c0-.28 0-.42.054-.527a.5.5 0 01.219-.218C6.38 14 6.52 14 6.8 14h.4c.28 0 .42 0 .527.055a.5.5 0 01.218.218C8 14.38 8 14.52 8 14.8v.4c0 .28 0 .42-.054.527a.5.5 0 01-.219.218C7.62 16 7.48 16 7.2 16h-.4c-.28 0-.42 0-.527-.055a.5.5 0 01-.218-.218C6 15.62 6 15.48 6 15.2v-.4z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24PaymentCardOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24PaymentCardOutline: FC<Icon24PaymentCardOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24PaymentCardOutline as any).mountIcon = mountIcon;

export default Icon24PaymentCardOutline;
