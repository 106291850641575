import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 20 20';
const id = 'water_drop_outline_20';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" id="water_drop_outline_20"><path fill-rule="evenodd" clip-rule="evenodd" d="M5.4 8.683c-.419.85-.65 1.647-.65 2.317a5.25 5.25 0 1010.5 0c0-.67-.231-1.467-.65-2.317-.414-.842-.988-1.69-1.615-2.447-.628-.759-1.292-1.408-1.874-1.86a4.888 4.888 0 00-.76-.499 1.644 1.644 0 00-.255-.106A.419.419 0 0010 3.75a.42.42 0 00-.096.02c-.065.02-.15.054-.254.107a4.854 4.854 0 00-.76.5c-.583.451-1.247 1.1-1.875 1.859-.627.757-1.201 1.605-1.615 2.447zm3.573-6.145c.308-.155.667-.288 1.027-.288.36 0 .72.133 1.027.288.322.163.662.389 1.003.654.684.53 1.425 1.26 2.11 2.087.686.828 1.33 1.774 1.806 2.742.473.96.804 1.992.804 2.979a6.75 6.75 0 01-13.5 0c0-.987.331-2.018.804-2.979.476-.969 1.12-1.914 1.806-2.742.685-.827 1.426-1.557 2.11-2.087a6.44 6.44 0 011.003-.654z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon20WaterDropOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon20WaterDropOutline: FC<Icon20WaterDropOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 20,
    height: !isNaN(props.height) ? +props.height : 20,
  }));
};

(Icon20WaterDropOutline as any).mountIcon = mountIcon;

export default Icon20WaterDropOutline;
