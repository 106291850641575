import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'discussions_circle_fill_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="discussions_circle_fill_28"><linearGradient id="discussions_circle_fill_28_a" gradientUnits="userSpaceOnUse" x1="-14" x2="14" y1="14" y2="42"><stop offset="0" stop-color="#70b2ff" /><stop offset="1" stop-color="#5c9ce6" /></linearGradient><path d="M0 14C0 6.268 6.268 0 14 0s14 6.268 14 14-6.268 14-14 14S0 21.732 0 14z" fill="url(#discussions_circle_fill_28_a)" /><path d="M20.398 12c.557 0 .759.058.963.167.203.109.363.269.472.472.097.182.154.361.165.79l.002.173v3.796c0 .557-.058.759-.167.963a1.135 1.135 0 01-.472.472c-.182.097-.361.154-.79.165l-.173.002H20v1.426a.57.57 0 01-.935.437l-.073-.073L17.5 19h-2.898c-.557 0-.759-.058-.963-.167a1.135 1.135 0 01-.472-.473c-.097-.18-.154-.36-.165-.79L13 17.399l.003-.399c1.321-.011 1.826-.161 2.334-.433.53-.283.946-.699 1.229-1.229.266-.498.416-.993.432-2.257l.002-.246V12zm-7-4c.557 0 .759.058.963.167s.363.269.472.472c.109.204.167.406.167.963v3.796c0 .557-.058.759-.167.963a1.135 1.135 0 01-.472.472c-.204.109-.406.167-.963.167H10.5l-1.492 1.79A.57.57 0 018 16.427V15h-.398c-.557 0-.759-.058-.963-.167-.203-.109-.363-.269-.472-.472S6 13.955 6 13.398V9.602c0-.557.058-.759.167-.963s.269-.363.472-.472c.204-.109.406-.167.963-.167z" fill="#fff" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28DiscussionsCircleFillProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28DiscussionsCircleFill: FC<Icon28DiscussionsCircleFillProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28DiscussionsCircleFill as any).mountIcon = mountIcon;

export default Icon28DiscussionsCircleFill;
