import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 36 36';
const id = 'cancel_36';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" id="cancel_36"><g fill="none" fill-rule="evenodd"><path d="M0 0h36v36H0z" /><path d="M19.414 18l7.793 7.793a1 1 0 01-1.414 1.414L18 19.414l-7.793 7.793a1 1 0 11-1.414-1.414L16.586 18l-7.793-7.793a1 1 0 011.414-1.414L18 16.586l7.793-7.793a1 1 0 011.414 1.414L19.414 18z" fill="currentColor" fill-rule="nonzero" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon36CancelProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon36Cancel: FC<Icon36CancelProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 36,
    height: !isNaN(props.height) ? +props.height : 36,
  }));
};

(Icon36Cancel as any).mountIcon = mountIcon;

export default Icon36Cancel;
