import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 56 56';
const id = 'diamond_outline_56';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56 56" id="diamond_outline_56"><path clip-rule="evenodd" d="M19 7a1.5 1.5 0 00-.67.158l-6.973 3.486c-.233.11-.44.283-.596.51L3.277 22.132a1.49 1.49 0 00-.275.945 1.494 1.494 0 00.397.943L26.87 50.487A1.51 1.51 0 0028 51a1.497 1.497 0 001.13-.513l23.469-26.465a1.495 1.495 0 00.38-1.272 1.48 1.48 0 00-.255-.618l-7.485-10.977a1.493 1.493 0 00-.597-.51l-6.971-3.487A1.5 1.5 0 0037 7zM6.756 22.354l9.059 3.986 1.536-9.987-4.85-2.425zm36.743-8.426l5.745 8.426-9.059 3.986-1.536-9.987zM35.713 17H20.287l-1.539 10h18.504zm-20.359-5l4-2h17.292l4 2-4 2H19.354zM45.83 27.134L35.03 39.312l5.055-9.65zM19.48 30h17.042L28 46.267zm-9.308-2.866l5.744 2.527 5.055 9.65z" fill="currentColor" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon56DiamondOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon56DiamondOutline: FC<Icon56DiamondOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 56,
    height: !isNaN(props.height) ? +props.height : 56,
  }));
};

(Icon56DiamondOutline as any).mountIcon = mountIcon;

export default Icon56DiamondOutline;
