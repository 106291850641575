import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'advertising_outline_28';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="advertising_outline_28"><g fill-rule="nonzero" fill="none"><path d="M0 0h28v28H0z" /><path d="M13.952 20.477a3 3 0 01-5.908 1.042l-.458-2.598A5.5 5.5 0 018.5 8h7.917l3.972-2.528A3 3 0 0125 8.002v10.996a3 3 0 01-4.611 2.53L16.417 19h-2.725l.26 1.477zm-3.939.694a1 1 0 001.97-.347l-.322-1.825h-2.03l.382 2.172zM21.463 7.16L17 10v7l4.463 2.841A1 1 0 0023 18.998V8.002a1 1 0 00-1.537-.843zM15 9.999L8.5 10a3.5 3.5 0 000 7H15v-7z" fill="currentColor" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28AdvertisingOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28AdvertisingOutline: FC<Icon28AdvertisingOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28AdvertisingOutline as any).mountIcon = mountIcon;

export default Icon28AdvertisingOutline;
