import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'users_3_outline_24';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="users_3_outline_24"><g fill="currentColor"><path clip-rule="evenodd" d="M8.5 8.1a3.5 3.5 0 117 0 3.5 3.5 0 01-7 0zM12 9.8a1.7 1.7 0 110-3.4 1.7 1.7 0 010 3.4zM12 13.1c-1.504 0-2.925.354-4.002 1.015-1.078.662-1.898 1.702-1.898 3.04 0 .81.279 1.532.852 2.042.558.498 1.293.703 2.048.703h6c.755 0 1.49-.205 2.05-.703.572-.51.85-1.233.85-2.042 0-1.338-.82-2.378-1.898-3.04C14.926 13.454 13.504 13.1 12 13.1zm-4.1 4.055c0-.518.306-1.055 1.04-1.506.737-.453 1.815-.749 3.06-.749s2.324.296 3.06.749c.735.451 1.04.988 1.04 1.506 0 .386-.121.585-.249.699-.141.126-.406.246-.85.246H9c-.446 0-.71-.12-.852-.246-.127-.114-.249-.313-.249-.7z" fill-rule="evenodd" /><path d="M5.903 13.7a.9.9 0 00-.9-.9c-2.006 0-3.95 1.276-3.95 3.357 0 1.263.87 2.743 2.607 2.743a.9.9 0 100-1.8.704.704 0 01-.575-.254 1.088 1.088 0 01-.232-.689c0-.73.74-1.557 2.15-1.557a.9.9 0 00.9-.9zM6.314 5.46a.9.9 0 11.255 1.78A1.243 1.243 0 005.5 8.467c0 .68.555 1.239 1.25 1.239a.9.9 0 010 1.8A3.044 3.044 0 013.7 8.466 3.043 3.043 0 016.314 5.46zM18.096 13.7a.9.9 0 01.9-.9c2.007 0 3.95 1.276 3.95 3.357 0 1.263-.87 2.743-2.607 2.743a.9.9 0 010-1.8c.272 0 .45-.104.576-.254.14-.167.231-.417.231-.689 0-.73-.739-1.557-2.15-1.557a.9.9 0 01-.9-.9zM17.686 5.46a.9.9 0 10-.255 1.78 1.243 1.243 0 011.07 1.226c0 .68-.556 1.239-1.25 1.239a.9.9 0 100 1.8c1.679 0 3.05-1.356 3.05-3.039a3.043 3.043 0 00-2.615-3.007z" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24Users3OutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24Users3Outline: FC<Icon24Users3OutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24Users3Outline as any).mountIcon = mountIcon;

export default Icon24Users3Outline;
