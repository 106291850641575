import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'chats_24';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="chats_24"><g fill="none"><path d="M0 0h24v24H0z" /><path d="M18.201 7.507C21.018 8.517 23 10.932 23 13.75c0 1.837-.843 3.503-2.211 4.718.146.703.472 1.58.977 2.633l.147.3.029.076a.4.4 0 01-.48.491c-1.794-.428-3.123-1.011-3.986-1.75a8.82 8.82 0 01-2.226.282c-2.786 0-5.228-1.28-6.594-3.202l.094.002c5.223 0 9.55-3.768 9.55-8.55 0-.423-.034-.838-.099-1.243zM8.75 2c4.28 0 7.75 3.022 7.75 6.75s-3.47 6.75-7.75 6.75a8.82 8.82 0 01-2.226-.283c-.863.74-2.192 1.323-3.986 1.751a.4.4 0 01-.48-.491l.029-.076.147-.3c.505-1.052.831-1.93.977-2.633C1.843 12.253 1 10.588 1 8.75 1 5.022 4.47 2 8.75 2z" fill="currentColor" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24ChatsProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24Chats: FC<Icon24ChatsProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24Chats as any).mountIcon = mountIcon;

export default Icon24Chats;
