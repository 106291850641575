import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 56 56';
const id = 'accessibility_outline_56';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56 56" id="accessibility_outline_56"><g fill="currentColor"><path d="M28 21a4 4 0 100-8 4 4 0 000 8z" /><path d="M30.5 30c0 1.116.227 2.221.667 3.247l3.712 8.662a1.5 1.5 0 11-2.758 1.182l-3.712-8.662a10.785 10.785 0 01-.084-.202.36.36 0 00-.333-.227.36.36 0 00-.336.236c-.077.208-.16.414-.248.619l-3.53 8.236a1.5 1.5 0 11-2.757-1.182l3.53-8.236a10.5 10.5 0 00.849-4.136v-2.678a21.88 21.88 0 01-9.8-3.616 1.5 1.5 0 111.68-2.486C20.41 22.804 24.063 24 28 24s7.59-1.196 10.62-3.243a1.5 1.5 0 111.68 2.486 21.88 21.88 0 01-9.8 3.616z" /><path clip-rule="evenodd" d="M52 28c0 13.255-10.745 24-24 24S4 41.255 4 28 14.745 4 28 4s24 10.745 24 24zm-3 0c0 11.598-9.402 21-21 21S7 39.598 7 28 16.402 7 28 7s21 9.402 21 21z" fill-rule="evenodd" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon56AccessibilityOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon56AccessibilityOutline: FC<Icon56AccessibilityOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 56,
    height: !isNaN(props.height) ? +props.height : 56,
  }));
};

(Icon56AccessibilityOutline as any).mountIcon = mountIcon;

export default Icon56AccessibilityOutline;
