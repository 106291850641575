import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'advertising_outline_24';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="advertising_outline_24"><path fill-rule="evenodd" clip-rule="evenodd" d="M18.567 6.914a.819.819 0 00-.573.09l-.025.015-3.757 1.875H7.6c-.77 0-1.453.285-1.937.729-.442.404-.763 1.174-.763 1.867 0 .692.321 1.462.763 1.867.248.227.548.412.885.54.321.12.676.188 1.052.188h6.612l3.757 1.876.025.015a.81.81 0 00.406.106c.218 0 .4-.08.523-.193a.504.504 0 00.177-.373V7.463a.478.478 0 00-.09-.274.703.703 0 00-.443-.275zm-1.452-1.478a2.622 2.622 0 011.811-.283 2.5 2.5 0 011.563 1.011c.265.382.411.832.411 1.3v8.052c0 .68-.302 1.279-.76 1.698a2.572 2.572 0 01-1.74.665c-.45 0-.894-.114-1.285-.335l-3.327-1.661H7.6c-.334 0-.66-.034-.976-.1a4.597 4.597 0 01-2.178-1.102C3.585 13.893 3.1 12.617 3.1 11.49c0-1.128.485-2.403 1.346-3.192A4.663 4.663 0 017.6 7.097h6.188l3.327-1.661z" fill="currentColor" /><path fill-rule="evenodd" clip-rule="evenodd" d="M14.9 7.995v6.99h-1.8v-6.99h1.8z" fill="currentColor" /><path d="M8.776 17.618l-.307-1.735H7.6c-.334 0-.66-.034-.976-.1l.38 2.147c.116.664.505 1.21 1.021 1.554a2.5 2.5 0 001.824.377 2.497 2.497 0 001.583-.977c.368-.5.546-1.145.429-1.81l-.21-1.191h-1.83l.266 1.504a.561.561 0 01-.107.434.697.697 0 01-.445.27.698.698 0 01-.51-.102.562.562 0 01-.25-.371z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24AdvertisingOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24AdvertisingOutline: FC<Icon24AdvertisingOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24AdvertisingOutline as any).mountIcon = mountIcon;

export default Icon24AdvertisingOutline;
