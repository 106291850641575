import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 56 56';
const id = 'advertising_outline_56';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56 56" id="advertising_outline_56"><path fill-rule="evenodd" clip-rule="evenodd" d="M38.813 15.28c1.575-.831 2.665-1.405 3.52-1.76.857-.357 1.26-.396 1.494-.365a2.5 2.5 0 011.712 1.032c.137.192.29.567.375 1.493.084.92.086 2.153.086 3.934v14.77c0 1.782-.002 3.014-.086 3.935-.085.925-.238 1.3-.375 1.493a2.5 2.5 0 01-1.712 1.032c-.233.03-.637-.008-1.495-.365-.854-.356-1.944-.93-3.52-1.76L31 34.594v-15.19l7.813-4.124zM15 20h13v14H15a7 7 0 110-14zm22.48-7.408l-8.351 4.407H15C9.477 17 5 21.476 5 27c0 5.276 4.086 9.598 9.266 9.974l1.601 8.003a5.077 5.077 0 009.98-1.866L24.786 37h4.343l8.351 4.407c1.493.787 2.705 1.427 3.701 1.842 1.007.419 2.01.705 3.04.569a5.5 5.5 0 003.766-2.27c.601-.848.816-1.869.916-2.955C49 37.518 49 36.148 49 34.46V19.538c0-1.687 0-3.058-.098-4.132-.1-1.087-.315-2.107-.916-2.955a5.5 5.5 0 00-3.766-2.27c-1.03-.136-2.033.15-3.04.569-.996.414-2.208 1.054-3.7 1.842zM18.808 44.388L17.33 37h4.409l1.152 6.624a2.077 2.077 0 01-4.083.764z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon56AdvertisingOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon56AdvertisingOutline: FC<Icon56AdvertisingOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 56,
    height: !isNaN(props.height) ? +props.height : 56,
  }));
};

(Icon56AdvertisingOutline as any).mountIcon = mountIcon;

export default Icon56AdvertisingOutline;
