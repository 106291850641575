import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'message_add_badge_outline_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="message_add_badge_outline_28"><path fill-rule="evenodd" clip-rule="evenodd" d="M12.879 4.51a1 1 0 01-.81 1.16c-4.413.78-7.532 4.081-7.532 7.829 0 1.537.514 2.979 1.422 4.2a1 1 0 01.197.597c0 1.042-.274 2.052-.61 2.963a25.572 25.572 0 01-.705 1.675c2.056-.219 3.405-.877 4.219-1.834a1 1 0 011.113-.289 10.94 10.94 0 003.845.687c3.733 0 6.9-1.815 8.448-4.367a1 1 0 011.71 1.038c-1.948 3.21-5.8 5.329-10.158 5.329-1.373 0-2.693-.21-3.918-.597C8.656 24.25 6.556 24.898 3.988 25c-1.046.041-1.8-1.047-1.363-2.021l.27-.597c.276-.605.547-1.199.775-1.815.256-.694.43-1.349.475-1.966-1.017-1.48-1.608-3.228-1.608-5.101 0-4.942 4.055-8.892 9.183-9.799a1 1 0 011.159.81zM4.449 23.8l.001-.004-.001.004z" fill="currentColor" /><path d="M19.5 3.5a6 6 0 110 12 6 6 0 010-12zm0 2a1 1 0 00-.993.883L18.5 6.5v2h-2a1 1 0 00-.117 1.993l.117.007h2v2a1 1 0 001.993.117l.007-.117v-2h2a1 1 0 00.117-1.993L22.5 8.5h-2v-2a1 1 0 00-1-1z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28MessageAddBadgeOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28MessageAddBadgeOutline: FC<Icon28MessageAddBadgeOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28MessageAddBadgeOutline as any).mountIcon = mountIcon;

export default Icon28MessageAddBadgeOutline;
