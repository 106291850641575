import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'notification_check_outline_24';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="notification_check_outline_24"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z" /><path d="M12.004 2.1c.675 0 1.326.092 1.944.273a.9.9 0 01-.506 1.728 5.097 5.097 0 00-1.438-.201c-2.895 0-5 2.31-5.1 5.491l-.004.24v1.6c0 .792-.336 1.375-1.055 2.147l-.727.75c-.535.572-.718.884-.718 1.2 0 .191.014.291.057.381.1.208.387.367 1.106.388l.174.003h12.526c.82 0 1.154-.169 1.272-.397.05-.095.065-.197.065-.376 0-.315-.183-.627-.718-1.198l-.554-.57-.258-.273a6.806 6.806 0 01-.216-.249.9.9 0 111.388-1.145c.075.09.16.185.266.297l.383.393c1.081 1.097 1.509 1.729 1.509 2.745 0 .445-.052.791-.268 1.206-.43.826-1.311 1.308-2.619 1.362l-.25.005h-1.886a.917.917 0 01-.006.026C15.754 20.306 14.245 21.6 12 21.6c-2.245 0-3.754-1.294-4.371-3.674l-.006-.026H5.737c-1.488 0-2.466-.502-2.902-1.41-.191-.398-.235-.72-.235-1.163 0-.953.376-1.568 1.314-2.544l.465-.476.237-.253c.315-.35.455-.582.48-.764l.004-.059v-1.6C5.1 5.38 7.988 2.1 12.004 2.1zm2.498 15.8H9.498c.45 1.319 1.245 1.9 2.502 1.9s2.051-.581 2.502-1.9zm4.962-13.636a.9.9 0 111.272 1.272l-4.2 4.2a.9.9 0 01-1.272 0l-2-2a.9.9 0 111.272-1.272L15.9 7.827z" fill="currentColor" fill-rule="nonzero" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24NotificationCheckOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24NotificationCheckOutline: FC<Icon24NotificationCheckOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24NotificationCheckOutline as any).mountIcon = mountIcon;

export default Icon24NotificationCheckOutline;
