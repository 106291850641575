import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'logo_vk_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="logo_vk_28"><path d="M17.373 3C23.533 3 25 4.467 25 10.627v6.746C25 23.533 23.533 25 17.373 25h-6.746C4.467 25 3 23.533 3 17.373v-6.746C3 4.467 4.467 3 10.627 3h6.746zM8.73 9.875H7.125c-.458 0-.55.216-.55.454 0 .425.544 2.532 2.532 5.32 1.326 1.903 3.194 2.934 4.893 2.934 1.02 0 1.146-.229 1.146-.624v-1.438c0-.459.096-.55.42-.55.237 0 .645.119 1.597 1.037 1.088 1.087 1.267 1.575 1.879 1.575h1.604c.458 0 .687-.229.555-.681-.145-.45-.664-1.105-1.353-1.88-.374-.442-.935-.918-1.105-1.156-.237-.306-.17-.441 0-.713l.029-.04c.242-.345 1.94-2.78 2.13-3.648.102-.34 0-.59-.485-.59h-1.605c-.407 0-.595.216-.697.454 0 0-.816 1.988-1.972 3.28-.374.374-.544.493-.748.493-.102 0-.25-.12-.25-.46v-3.177c0-.408-.117-.59-.457-.59h-2.521c-.255 0-.409.19-.409.369 0 .386.578.476.638 1.563v2.363c0 .518-.094.611-.298.611-.543 0-1.866-1.997-2.65-4.282-.155-.445-.309-.624-.719-.624z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28LogoVkProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28LogoVk: FC<Icon28LogoVkProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28LogoVk as any).mountIcon = mountIcon;

export default Icon28LogoVk;
