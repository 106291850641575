import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'favorite_circle_fill_green_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="favorite_circle_fill_green_28"><path d="M0 14C0 6.268 6.268 0 14 0s14 6.268 14 14-6.268 14-14 14S0 21.732 0 14z" fill="url(#favorite_circle_fill_green_28_a)" /><path d="M16.346 10.986l3.107.305c1.08.106 1.412 1.186.578 1.896l-2.432 2.068.902 3.37c.297 1.106-.623 1.776-1.544 1.11L14 17.599l-2.955 2.136c-.917.663-1.841-.004-1.545-1.11l.903-3.37-2.432-2.068c-.838-.712-.508-1.79.578-1.896l3.106-.305 1.368-3.228c.429-1.01 1.526-1.01 1.955 0l1.367 3.228z" fill="#fff" /><defs><linearGradient id="favorite_circle_fill_green_28_a" x1="-14" y1="14" x2="14" y2="42" gradientUnits="userSpaceOnUse"><stop stop-color="#50C750" /><stop offset="1" stop-color="#32B332" /></linearGradient></defs></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28FavoriteCircleFillGreenProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28FavoriteCircleFillGreen: FC<Icon28FavoriteCircleFillGreenProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28FavoriteCircleFillGreen as any).mountIcon = mountIcon;

export default Icon28FavoriteCircleFillGreen;
